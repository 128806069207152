@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$menu-account-incentive-message-background-color: $color-primary !default;
$menu-account-incentive-message-text-color: white !default;

.signup-menu-account {
	&__signup-intro {
		padding: 19px 30px;
		background: $menu-account-incentive-message-background-color;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.2rem;
		color: $menu-account-incentive-message-text-color;
		border-radius: 0;

		@include gridle_state(md) {
			border-radius: $border-radius-big $border-radius-big 0 0;
		}

		svg {
			margin-right: 15px;

			& + * {
				flex: 1 1 0%;
			}
		}
	}

	&__nav {
		:first-child.account-menu__footer {
			border-top: none;
		}

		&-item {
			padding-left: 20px;
			padding-right: 20px;
			height: 60px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $grey-medium;

			svg {
				margin-right: 28px;
			}
		}
	}

	&__dynamic-message {
		padding: 30px 15px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		color: $black;
		background-color: $grey-light;

		& .relative-link {
			justify-content: flex-start;
		}

		li {
			vertical-align: baseline;

			// list-style-image: url("/static/icons/sdp-checkbox.svg");
			list-style-position: inside;
			position: relative;
			line-height: 20px;

			// add bulleted list with padding-left
			padding-left: 14.8px;
			list-style-type: none;

			&::before {
				content: "";
				position: absolute;
				top: 8px;
				left: 0;
				display: block;
				width: 4.8px;
				height: 4.8px;
				border-radius: 100%;
				background-color: $black;
			}

			@include gridle_state(md) {
				padding-left: 12.8px;
			}
		}

		@include gridle_state(md) {
			padding: 20px;
		}
	}

	&__signup-login-button {
		@extend %link;

		display: flex;
		gap: 5px;
		height: 27px;
		align-items: center;

		& svg {
			width: 10px;
			height: 10px;
		}
	}
}
