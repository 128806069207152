@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.tripadvisor-subratings {
	display: flex;
	flex-direction: column;
	color: $black;

	&__subrating {
		display: flex;
		align-items: center;
		margin-bottom: 2px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__name {
		font-size: 1.2rem;
		line-height: 140%;
	}

	.tripadvisor-rating-image {
		height: 12px;
		min-width: 68px;

		img {
			height: 16px;
			top: -2px;
			left: 0;
		}
	}
}
