@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.smartDP-hero-commercial {
	&__image {
		&-background {
			height: 100%;
		}

		&-headline {
			padding: 24px 16px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 221px;

			@include gridle_state(md) {
				position: absolute;
				top: 0;
				width: 100%;
				height: calc(100% - 120px);
				margin-top: 0;
				padding: 0;
			}

			.smartdp-marketing-headline {
				padding: 0 16px;
				@include gridle_state(md) {
					padding: 0;
				}

				&__title {
					text-align: center;
					line-height: 30px;
					@include gridle_state(md) {
						line-height: 64px;
					}


				}

				&__subtitle {
					margin-top: 16px;
					text-align: center;
					@include gridle_state(md) {
						margin-top: 24px;
					}
				}
			}

		}
	}

	&__form {
		margin: 0 16px 6px;
		@include gridle_state(md) {
			position: absolute;
			bottom: 40px;
			max-width: 1024px;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
		}
	}

	&__tag {
		padding: 6px;
		margin-bottom: 16px;
		box-sizing: border-box;
		text-align: center;
		background: var(--color-gray-700, $grey-dark);
		color: white;
		width: fit-content;
		@include gridle_state(md) {
			margin-bottom: 24px;
			padding: 8px;

		}
	}

	&__redirect--button {
		padding-top: 16px;
		@include gridle_state(md) {
			padding-top: 24px;
		}

		.button--secondary {
			padding-right: 8px;
			border: unset;
			font-size: unset;
			display: flex;
			align-items: center;
		}

		svg {
			padding-left: 4px;
		}
	}

}