@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.merchandising-medias {
	display: flex;
	flex-direction: column-reverse;
	@include gridle_state(md) {
		flex-direction: unset;
	}

	&__caroussel {
		width: calc(100vw - 16px);

		@include gridle_state(md) {
			@include gridle_grid(16);
		}
	}
}
