@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$menu-account-menu-item-color: $black !default;
$side-panel-header-height: 64px;

.shop-switcher-detail-modal {
	position: relative;
	background: white;
	border-radius: 10px;
	padding: 10px 14px 14px;
	margin-right: 14px;
	margin-left: 14px;
	z-index: 5;
	width: 284px;

	@include gridle_state(md) {
		padding: 32px 40px;
	}

	&__content {
		padding: 32px 24px;
		@include gridle_state(md) {
			padding: 0;
		}
	}

	&:focus {
		outline: none;
	}

	&__title {
		color: $menu-account-menu-item-color;
		margin-bottom: 24px;

		@include gridle_state(md) {
			color: $black; // always black in desktop because it is in a modal
		}
	}

	&__close {
		position: absolute;
		top: 14px;
		right: 14px;
		cursor: pointer;
		height: 10px;
		width: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--color-gray-600, $grey-dark);

		@include gridle_state(md) {
			top: 16px;
			right: 16px;
			height: 16px;
			width: 16px;
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 5;

		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: 0.7;
			z-index: -1;
		}
	}

	&__side-panel {
		width: 100vw;
		height: 100dvh;
		right: 0;
		background: $header-background-color;
		transform: translateX(100%) !important;

		&.aside-panel--pushed {
			transform: translateX(0) !important;
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: sticky;
			height: $side-panel-header-height;
			padding: 0 16px;
			box-sizing: border-box;
			border-bottom: 1px solid var(--color-header-bottom-border, $header-border-bottom);
			color: $header-text-color;
			overflow: hidden;

			.logo-brand__logo {
				height: $side-panel-header-height;
				max-width: 100%;
			}
		}

		&-content {
			height: calc(100% - $side-panel-header-height);
			overflow: auto;
		}

		&--eh {
			.logo-brand {
				height: 84px;
				transform: translateY(-10px);
			}
		}
	}
}
