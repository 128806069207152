@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.tripadvisor-rating {
	display: flex;
	flex-direction: column;

	&__infos {
		display: grid;
		grid-template-columns: 70px 1fr;
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 12px;
		grid-row-gap: 2px;
		color: $black;
	}

	&__note {
		@extend %text-bold;

		grid-column: 1 / 2;
		grid-row: 1 / 3;
		font-size: 4.8rem;
		line-height: 100%;
		letter-spacing: -0.48px;
	}

	&__status {
		@extend %text-bold;

		grid-column: 2 / 3;
		grid-row: 1 / 2;
		font-size: 1.6rem;
	}

	&__ranking {
		margin-top: 8px;
		font-size: 1.2rem;
		line-height: 140%;
	}

	.tripadvisor-rating-image {
		grid-column: 2 / 3;
		grid-row: 2 / 3;

		img {
			top: -1px;
		}
	}
}
