@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$header-background-color: white !default;
$header-text-color: $black !default;

.logo-header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding: 10px;
	box-sizing: border-box;
	z-index: 2;
	background: $header-background-color;
	color: $header-text-color;

	.logo-brand {
		height: 64px;
	}

	@include gridle_state(md) {
		justify-content: flex-start;
	}

	&__title {
		display: flex;
		align-items: center;

		@include gridle_state(md) {
			margin-left: 40px;
		}
	}
}
