@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper";

.media-carousel {
	max-height: 100% !important;
	height: 100% !important;
	border-radius: $border-radius-big;

	&:hover {
		.media-carousel__button {
			opacity: 1;
			transform: scale(1.1);
		}
	}

	.swiper-wrapper--start {
		transform: unset !important;
	}

	.swiper-slide {
		margin: auto;
		transform: scale(0.9);

		.media-carousel-item__photo {
			overflow: hidden;
			transition: transform 1s ease;
			border-radius: $border-radius-big;

			img {
				transition: transform 1s ease !important;
			}

			video {
				object-fit: cover;
			}


		}

		&-active {
			transition: transform 1s ease;
			transform: scale(1);

			.media-carousel-item__photo {
				img {
					transform: scale(1);
				}

				&:hover img {
					transform: scale(1.2);

				}
			}

			.media-carousel-item__photo {
				&::after {
					content: none;
				}
			}
		}
	}

	&__button {
		position: absolute;
		top: calc(50% - 10px);
		z-index: 3;
		background-color: white;
		border-radius: 100%;
		padding: 10px;
		width: 15px;
		height: 15px;
		color: black;
		opacity: 0;

		@include gridle_state(md) {
			pointer-events: none; // let the entire slide be clickable
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		}

		&--next {
			right: 16px;
		}

		&--previous {
			left: 16px;
		}
	}

	&__nav {
		position: absolute;
		top: 0;
		width: 54px;
		z-index: 1;

		@include gridle_state(md) {
			cursor: pointer;
		}
	}

	.media-carousel__nav--previous {
		left: 0;
	}

	.media-carousel__nav--next {
		right: 0;
	}

	&__caroussel {
		padding: 24px 0 32px;
		height: 198px;

		@include gridle_state(md) {
			padding: 0;
			height: 100%;
		}
	}

	&__photos {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 82px;

		@include gridle_state(mc) {
			height: 139px;
		}
	}

	&__photo {
		width: 50%;
		margin-right: 20px;
		box-sizing: border-box;
		height: 100%;

		&:last-child {
			margin-right: 0;
		}
	}
}
