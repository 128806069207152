@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$auth-form-text-color-static: $black !default;
$auth-form-background-static: white !default;
$auth-form-box-shadow-static: 0 1px 14px 0 rgba(5, 16, 57, 0.4) !default;
$auth-form-border-color-static: rgba(255, 255, 255, 0.3) !default;

.auth-layer {
	width: 100%; // eviter que la largeur ne change au switch sign login

	&__marketing {
		flex: 1;
		display: flex;
		align-items: center;
		text-align: left;

		@include gridle_state(md) {
			margin-right: 20px;
			padding: 0 24px;
		}
	}

	.auth {
		// pour supprimer le max-width: 307px du fichier auth pour ne pas casser les autres brands
		max-width: initial;

		&__forgotten-password {
			justify-content: flex-end;
			align-items: flex-start;

			.relative-link {
				font-size: 1rem;
				color: $auth-form-text-color-static;
			}
		}

		&__switch {
			&__item {
				color: $auth-form-text-color-static;
			}
		}

		&__headline {
			color: $auth-form-text-color-static;
		}

		&__separator {
			color: $auth-form-text-color-static;

			&::before,
			&::after {
				background: $auth-form-text-color-static;
			}
		}

		&__accept-cgv {
			color: $auth-form-text-color-static;

			.relative-link,
			svg {
				color: $auth-form-text-color-static;
			}
		}

		&__password {
			.control-group__note svg {
				fill: $black;
			}
		}

		.password-policy__label {
			color: $auth-form-text-color-static;
		}

		&__credit {
			&-banner {
				color: $auth-form-text-color-static;

				.amount {
					color: $auth-form-text-color-static;
				}
			}
		}
	}

	&__auth-form {
		background: $auth-form-background-static;
		box-shadow: $auth-form-box-shadow-static;
		border: 1px solid $auth-form-border-color-static;
		border-radius: 5px;

		@include gridle_state(md) {
			background: none;
		}

		.control-group__label,
		.control-group--touched .control-group__label {
			// color: $auth-form-text-color-static;
			color: $black; // regression other brand
		}

		.control-group {
			&__input {
				color: $black;
				background: white;
				border-radius: $border-radius-small;
				border-color: $grey-medium;

				&:-webkit-autofill + .control-group__label {
					color: $black;
				}
			}

			&--error {
				.control-group__input {
					background: $auth-form-background-static;
					border-color: var(--color-warning-500);
					box-shadow: none;
				}

				.control-group__label {
					color: var(--color-warning-500);
				}
			}
		}

		.auth__action {
			margin-top: 25px;

			@include gridle_state(sm) {
				margin-top: 0;
			}
		}

		input {
			&:-webkit-autofill {
				-webkit-text-fill-color: $black;
			}
		}

		&--darksite {
			border-radius: 20px;
			background: none;

			.search-booking-form,
			.recover-booking-data-form {
				width: 260px;
				border-radius: 20px;
				background-color: rgba(15, 44, 57, 0.45);
				padding: 30px;

				&__title {
					display: none;
				}

				@include gridle_state(md) {
					background: none;
				}
			}
		}
	}

	&__photo {
		text-align: center;
	}

	&__close-icon {
		position: absolute;
		top: -48px;
		right: 0;

		svg {
			width: 32px;
			height: 32px;
		}
	}
}
