@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "../../.styles/placeholder-class";

$article-height: 388px;

.media-article {
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;

	&__title {
		&-label {
			text-align: left;
			margin-bottom: 32px;
			@include gridle_state(md) {
				margin-bottom: 15px;
			}
		}
	}

	&__article {
		box-sizing: border-box;
		text-align: left;
		@include gridle_state(md) {
			@include gridle_grid(8);

			display: flex;
			flex-direction: column;
			max-height: $article-height;
			padding-right: 26px;
			width: 100%;
		}
	}

	&__description {
		margin-bottom: 24px;

		@include gridle_state(md) {
			margin-bottom: 0;
			padding-right: 8px;
		}
	}

	&__scroller {
		@extend %scrollbar-thin;
		@include gridle_state(md) {
			height: 100%;
			overflow: auto;

			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $grey-medium;
			}
		}
	}

	.fade-in-up {
		animation: fade-in-up 0.7s ease-in-out;
	}

	@include gridle_state(md) {
		flex-direction: row;
		width: 360px;
	}
}

@keyframes fade-in-up {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}
