@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.tripadvisor-rating-image {
	display: flex;
	align-items: center;
	height: 16px;
	overflow: hidden;

	$this: &;

	&--without-logo {
		position: relative;
		min-width: 84px;

		img {
			position: absolute;
			top: -2px;
			left: -5px;
			height: 20px;
		}

		#{$this}__reviews-count {
			margin: 2px 0 0 84px;
		}
	}

	&__reviews-count {
		font-size: 1.4rem;
	}
}
